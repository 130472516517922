<template>
  <div class="popular-routes">
    <div class="popular-routes__wrapper">
      <Column class="popular-routes__limiter">
        <Row align="center"
             class="popular-routes__title-holder"
             justify="between">
          <h2 v-if="isHome" class="popular-routes__title">
            Популярные <a class="popular-routes__link" href="/trips">маршруты</a> и
            <a class="popular-routes__link" href="/excursions">экскурсии</a>
          </h2>
          <h2 v-else class="popular-routes__title">
            Популярные {{$trip.type === 'excursion' ? 'экскурсии' : 'маршруты'}}
          </h2>
          <Row v-if="!isHome"
               align="center"
               class="popular-routes__see-all">
            <router-link class="popular-routes__see-all-text"
                         :to="$trip.type === 'excursion' ? '/excursions' : '/trips'">
              {{$viewport.desktop ? 'смотреть ' : ''}}все
            </router-link>
            <Icon class="popular-routes__see-all-icon"
                  viewport="0 0 20 20"
                  :rotate="90"
                  xlink="arrow-navigation"/>
          </Row>
        </Row>
        <!--        <Row class="categories">-->
        <!--          <div class="category category__active">Все</div>-->
        <!--          <div class="category">Спорт</div>-->
        <!--          <div class="category">События</div>-->
        <!--          <div class="category">Сельский туризм</div>-->
        <!--          <div class="category">Паломничество</div>-->
        <!--          <div class="category">Круиз</div>-->
        <!--        </Row>-->
      </Column>
      <Loader v-if="$trips.data.length === 0"/>
      <Slider :items="$trips.data"
              :indentation="$viewport.desktop ? 30 : 8"
              :count="$viewport.desktop ? 3 : 1"
              component="PopularRoute"
              limiter
              v-else/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopularRoutes',
  props: {
    cityId: {
      default: null,
    },
    type: {
      default: null,
    },
    isHome: {
      default: false,
    },
  },
  methods: {
    getParams() {
      const params = {
      };
      if (this.cityId !== null) {
        params.city_id = this.$city.id
         || this.$cities.find(item => item.slug === this.$route.params.municipality).id;
      }
      if (this.type) {
        params.type = this.type;
      }
      return params;
    },
  },
  created() {
    setTimeout(() => {
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    }, 1000);
  },
  watch: {
    $city() {
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
  },
};
</script>
