<template>
  <div class="about-region">
    <div class="about-region__wrapper">
      <Row justify="between"
           :wrap="$viewport.mobile">
        <p class="about-region__title"
           v-if="$viewport.mobile">Общая <br/> информация</p>
        <Column class="about-region__image">
          <div class="about-region__image-holder">
            <img :src="$route.params.municipality === 'region' ? $region.image : $city.image"
                 alt=""
                 class="about-region__image-image">
          </div>
        </Column>
        <Column class="about-region__general-information">
          <p class="about-region__title"
             v-if="$viewport.desktop">Общая <br/> информация</p>
          <p class="about-region__description"
             v-html="$route.params.municipality === 'region' ?
             $region.description : $city.description"/>
          <!--          <Row class="about-region__info">-->
          <!--            <Icon class="about-region__info-icon"-->
          <!--                  viewport="0 0 40 40"-->
          <!--                  xlink="info"/>-->
          <!--            <span class="about-region__info-caption">-->
          <!--              По площади Томская область <br/> чуть больше Польши</span>-->
          <!--          </Row>-->
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutRegion',
};
</script>

<style lang="scss">
  @import './../assets/style/utilits/index';

  .about-region {
    min-height: 441px;

    &__wrapper {

    }

    &__general-information {
      @media (min-width: 1110px) {
        @include size(445px)
      }
      @media (min-width: 1px) and (max-width: 1109px) {
        @include size(100%);
      }
    }

    &__title {
      @media (min-width: 1110px) {
        @include font(40px, bold, 1.2);
      }
      @media (min-width: 1px) and (max-width: 1109px) {
        @include extend-title-mobile();
      }
      margin: 0 0 32px;
    }

    &__description {
      margin: 0 0 32px;
      @include font(16px, normal, 1.5);
      color: rgba(0, 0, 0, .58);
    }

    &__info {
      &-icon {
        margin: 0 21px 0 0;
        fill: $green;
      }

      &-caption {
        @media (min-width: 1110px) {
          @include font(16px, bold, 1.38);
        }
        @media (min-width: 1px) and (max-width: 1109px) {
          @include font(12px, normal, 1.5);
        }
        color: rgba(0, 0, 0, .58);
      }
    }

    &__image {
      @media (min-width: 1110px) {
        @include size(540px);
      }
      @media (min-width: 1px) and (max-width: 1109px) {
        @include size(100%);
      }
      @include position(relative);

      &-holder {
        @media (min-width: 1110px) {
          @include position(absolute, $top: 0, $right: 0);
          @include size(705px, 441px);
          border-radius: 5px 0 0 5px;
        }
        @media (min-width: 1px) and (max-width: 1109px) {
          @include size(100%, auto);
          border-radius: 5px;
          margin: 0 0 24px;
        }
        @include flex();
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }

      &-image {
        @include size(100%, auto);
      }
    }
  }
</style>
