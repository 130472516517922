<template>
  <div class="banner-interactive-card">
    <div class="banner-interactive-card__wrapper">
      <div class="banner-interactive-card__card">
        <Column>
          <img :src="require(`@/assets/images/svg/map-icon.svg`)"
               alt=""
               class="banner-interactive-card__icon">
          <p class="banner-interactive-card__title">Интерактивная карта</p>
          <p class="banner-interactive-card__subtitle"> </p>
          <p class="banner-interactive-card__description">Гастрономический туризм, детский,
            семейный, паломнический, культурно-познавательный, агротуризм и экотуризм</p>
          <router-link class="button button__green"
                       to="/interactive-map">Посмотреть карту
          </router-link>
        </Column>
      </div>
      <div v-if="$viewport.desktop">
        <div :key="point.id"
             :style="{top: `${point.top}px`, left: `${point.left}px`}"
             class="banner-interactive-card__point"
             v-for="point in points"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerInteractiveCard',
  data() {
    return {
      points: [{
        top: 153,
        left: 506,
      }, {
        top: 88,
        left: 727,
      }, {
        top: 238,
        left: 767,
      }, {
        top: 100,
        left: 868,
      }, {
        top: 254,
        left: 1004,
      }],
    };
  },
};
</script>
