<template>
  <div class="city">
    <div class="city__wrapper">
      <Section class="city__on-map-holder"
               limiter>
        <OnMap v-if="$city.location"
               :location="$city.location"
               :key="rerenderKey"
               :params="params"
               class="city__on-map"/>
        <BreadCrumbs :params="{navigation, color: 'rgba(0,0,0,.58)'}"/>
        <Row
          :wrap="$viewport.mobile"
        >
          <h1 class="city__h1">Начни открывать Сибирь <br v-if="$viewport.desktop">
            <span class="city__select"
                  v-on:click="$openPopupChooseMunicipality">c {{$city.declension}}</span>
          </h1>
        </Row>
      </Section>
      <Section class="city__about-city"
               limiter>
        <AboutRegion v-if="$city.description || $city.image"/>
      </Section>
      <Section limiter>
        <BannerInteractiveCard/>
      </Section>
      <Section>
        <PopularRoutes :cityId="$city.id"/>
      </Section>
      <Section>
        <InterestingFacts/>
      </Section>
      <Section>
        <Contents :cityId="$city.id"/>
      </Section>
      <Section>
        <Gallery v-if="$city.images && $city.images.length > 0"
                 id="gallery"
                 :params="{title: 'Фотографии Томской области', items: $city.images}"/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
import AboutRegion from '../components/AboutRegion.vue';
import BannerInteractiveCard from '../components/BannerInteractiveCard.vue';
import PopularRoutes from '../components/PopularRoutes/index.vue';
import InterestingFacts from '../components/InterestingFacts/index.vue';
import Gallery from '../components/Gallery.vue';

export default {
  name: 'CityView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  components: {
    Gallery,
    InterestingFacts,
    PopularRoutes,
    BannerInteractiveCard,
    AboutRegion,
  },
  data() {
    return {
      rerenderKey: 1,
      params: {
        sizeMap: {
          width: this.$viewport.desktop ? '100%' : '100%',
          height: this.$viewport.mobile ? 'calc(800px - 80px)' : 'auto',
        },
        controls: [],
      },
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: this.$route.path,
        title: 'Города Томской области',
      }],
    };
  },
  created() {
    this.$store.dispatch('GET_CITY_BY_SLUG', {
      params: {
        slug: this.$route.params.municipality,
      },
    });
  },
  watch: {
    $route() {
      this.$store.dispatch('GET_CITY_BY_SLUG', {
        params: {
          slug: this.$route.params.municipality,
        },
      });
    },
    $city() {
      this.rerenderKey += 1;
    },
  },
};
</script>
