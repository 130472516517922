<template>
  <div class="gallery">
    <div class="gallery__wrapper">
      <Column class="gallery__meta">
        <p class="gallery__title">{{params.title}}</p>
<!--        <p class="gallery__count">{{count}}</p>-->
      </Column>
      <Slider :items="params.items"
              :height="$viewport.desktop ? 450 : 180"
              :indentation="$viewport.desktop ? 30 : 8"
              :count="1"
              component="TripHeroImage"
              limiter/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Gallery',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    count() {
      return this.params.items.length;
    },
  },
};
</script>
